import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Store from 'store';

import {
  analyticsTrack,
  getAnonymousId,
  getTrackedCustomProps,
  QUOTE_FLOW,
} from '@pumpkincare/analytics';
import { MC_URL } from '@pumpkincare/config';
import { patchIdentity } from '@pumpkincare/identity';
import { setMarketingAttributionProperty } from '@pumpkincare/marketing';
import {
  getQuoteId,
  getQuotePets,
  getQuoteTrackingId,
  patchQuoteTracking,
  QUOTE_ID_COOKIE_NAME,
  transformFinalize,
  useFinalize,
  useQuote,
} from '@pumpkincare/quotes';
import {
  ARF_MEOW_PHONE_NUMBER,
  BannerType,
  COMPLETE_QUOTE_ID_LS_KEY,
  CONTACT_EMAIL,
  currentISODate,
  DUPLICATE_PET_ERROR,
  GENERIC_CONTACT_ERROR,
  GENERIC_PAYMENT_ERROR,
  getCookie,
  getIsLoggedIn,
  getStripeError,
  ITERABLE_CAMPAIGN_ID,
  ITERABLE_TEMPLATE_ID,
  navigateTo,
  PARTNER_VET_MODAL_PRESENTED,
  postBillingAddressValidate,
  postRatingAddressValidate,
  QUOTE_URL_PARAMS_KEY,
  removeCookie,
  SPRIG_SURVEY_PRESENTED,
  useBanners,
} from '@pumpkincare/shared';
import { getUserIsChargedAnnually, useUssr } from '@pumpkincare/user';

import { getAppAgentId, Paths } from '../../../app-shell';
import { getIdentityId } from '../../../identity';
import { resetQuotes } from '../../../quotes';
import {
  getQuotes,
  getQuotesBillingAddress,
  getQuotesShippingAddress,
  getShippingSameAsBilling,
} from '../../../quotes/selectors';
import {
  useIsChargedAnnuallyValue,
  usePaperLessValue,
  useTotalsValue,
} from '../checkout-provider';
import { addressReduxTransform } from './checkout-utils';

export default function useSubmitCheckout() {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const dispatch = useDispatch();
  const agentId = useSelector(getAppAgentId);
  const billingAddress = useSelector(getQuotesBillingAddress);
  const identityId = useSelector(getIdentityId);
  const quotes = useSelector(getQuotes);
  const shippingAddress = useSelector(getQuotesShippingAddress);
  const isSameAsShipping = useSelector(getShippingSameAsBilling);

  const { addBanner, removeAllBanners } = useBanners();
  const { grandTotal } = useTotalsValue();
  const { fer4310FixAnnualInvoiceForNewPet } = useFlags();
  const { data: userData } = useUssr();
  const isChargedAnnually = useIsChargedAnnuallyValue();
  const { termsVersion, isPaperLess } = usePaperLessValue();
  const [isLoading, setIsLoading] = useState(false);

  const { data: quoteData } = useQuote();
  const pets = getQuotePets(quoteData);
  const quoteId = getQuoteId(quoteData);

  const { mutateAsync: finalize } = useFinalize();

  function submitCheckout() {
    const iterableCampaignId = getCookie(ITERABLE_CAMPAIGN_ID);
    const iterableTemplateId = getCookie(ITERABLE_TEMPLATE_ID);

    return Promise.all([
      stripe.createToken(elements.getElement(CardNumberElement)).then(payload => {
        analyticsTrack(
          {
            category: QUOTE_FLOW,
            event: 'Submit Payment',
            label: payload.error ? 'Failed' : 'Success',
          },
          getTrackedCustomProps()
        );

        return payload.error ? Promise.reject() : Promise.resolve(payload.token.id);
      }),

      postRatingAddressValidate(
        addressReduxTransform({ country: { value: 'US' }, ...shippingAddress })
      ),

      postBillingAddressValidate(
        addressReduxTransform(
          isSameAsShipping
            ? { country: { value: 'US' }, ...shippingAddress }
            : billingAddress
        )
      ),
    ])
      .then(([stripeToken]) => {
        const postBody = transformFinalize(
          stripeToken,
          quotes,
          { agentId },
          isChargedAnnually,
          termsVersion,
          isPaperLess
        );

        return finalize({ quoteId, postBody });
      })
      .then(result => {
        const {
          token: { access_token },
          user,
        } = result;
        const { email } = user;

        analyticsTrack({
          event: 'Order Completed',
          email,
          total: parseFloat(grandTotal),
          campaignId: iterableCampaignId
            ? parseInt(iterableCampaignId)
            : iterableCampaignId,
          templateId: iterableTemplateId
            ? parseInt(iterableTemplateId)
            : iterableTemplateId,
          dataFields: {},
        });
        analyticsTrack(
          {
            category: 'Purchase Event',
            event: 'Finalize Success',
            label: `Policies: ${pets.length}, Plans: ${
              pets.filter(pet => !!pet.hasPrevent).length
            }`,
            created_at: currentISODate(),
          },
          getTrackedCustomProps()
        );

        setMarketingAttributionProperty({ isGoingToCheckoutSuccess: true });
        removeAllBanners();
        dispatch(resetQuotes());
        Store.remove(QUOTE_URL_PARAMS_KEY);
        Store.set(COMPLETE_QUOTE_ID_LS_KEY, getCookie(QUOTE_ID_COOKIE_NAME));
        removeCookie(QUOTE_ID_COOKIE_NAME);

        history.push(
          Paths.Hdyhau +
            '?' +
            new URLSearchParams({ email, token: access_token }).toString()
        );
      });
  }

  function submitCheckoutApld() {
    let postBody = {};

    if (fer4310FixAnnualInvoiceForNewPet) {
      const isLoggedUserAnnuallyCharged = getUserIsChargedAnnually(userData);
      postBody = { billed_annually: isLoggedUserAnnuallyCharged };
    }

    return finalize({
      quoteId,
      postBody,
    }).then(() => {
      Store.set(COMPLETE_QUOTE_ID_LS_KEY, getCookie(QUOTE_ID_COOKIE_NAME));
      Store.remove(SPRIG_SURVEY_PRESENTED);
      Store.remove(PARTNER_VET_MODAL_PRESENTED);
      removeCookie(QUOTE_ID_COOKIE_NAME);

      dispatch(resetQuotes());

      navigateTo(MC_URL);
    });
  }

  function getFinalizeErrorMessage(error) {
    const errorMessage = error?.response?.data?.message;

    const errorMap = {
      invalid_pet_parent: 'Invalid First Name or Last Name',
      missing_first_last_names: 'Please enter a valid First/Last Name',
      identity_not_found: `Try creating another quote or contacting our care team at ${CONTACT_EMAIL} or at ${ARF_MEOW_PHONE_NUMBER}.`,
    };

    const normalizedErrorMessage = errorMessage?.toLowerCase();

    for (const key in errorMap) {
      if (normalizedErrorMessage.includes(key)) {
        return errorMap[key];
      }
    }

    // Default empty if no match is found
    return '';
  }

  function submit() {
    setIsLoading(true);

    patchIdentity({
      id: identityId,
      city: shippingAddress.city.value,
      state_province: shippingAddress.state.value,
      zipcode: shippingAddress.zipcode.value,
      first_name: shippingAddress.firstName.value,
      last_name: shippingAddress.lastName.value,
    });

    const trackingId = getQuoteTrackingId(quoteData) || getAnonymousId();
    patchQuoteTracking({ quoteId, trackingId });

    return (getIsLoggedIn() ? submitCheckoutApld() : submitCheckout())
      .catch(error => {
        removeAllBanners();
        if (error?.response?.status === 400) {
          const stripeError = getStripeError(error);
          const httpErrorMessage = getFinalizeErrorMessage(error);
          const errorMessage = stripeError || httpErrorMessage;

          if (errorMessage) {
            addBanner({
              type: BannerType.ERROR,
              title: 'We are unable to process your payment.',
              message: errorMessage,
            });
          } else {
            addBanner(GENERIC_CONTACT_ERROR);
          }
        } else if (error?.response?.status === 409) {
          addBanner(DUPLICATE_PET_ERROR);
        } else if (error?.response?.status === 422) {
          const errors = error.response.data?.errors
            ? Object.values(error.response.data.errors)[0]
            : 'Invalid address';

          addBanner({
            type: BannerType.ERROR,
            title: `${
              error.config.url.includes('rating') ? 'Account' : 'Billing'
            } Address Error:`,
            message: errors,
          });
        } else {
          addBanner(GENERIC_PAYMENT_ERROR);
        }
        window.scrollTo(0, 0);
      })
      .finally(() => setIsLoading(false));
  }

  return { submit, isLoading };
}
