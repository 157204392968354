import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Store from 'store';

import { analyticsTrack, INITIATE_EXPERIMENT } from '@pumpkincare/analytics';
import { getDiscountId, useDiscount } from '@pumpkincare/discounts';
import { canGoToPlanPage } from '@pumpkincare/plans';
import { getQuotePets, QUOTE_ID_COOKIE_NAME, useQuote } from '@pumpkincare/quotes';
import {
  EMBARK,
  getCookie,
  getIsLoggedIn,
  QUOTE_URL_PARAMS_KEY,
} from '@pumpkincare/shared';
import { ProgressBar } from '@pumpkincare/shared/ui';

import { Paths } from '../../../app-shell';
import { getQuotesPartner } from '../../selectors';
import getQuotesActivePet from '../../selectors/get-quotes-active-pet';
import {
  calculateEmbarkQuoteFlowProgress,
  calculateQuoteFlowProgress,
} from '../../utils/quote-flow-progress-bar-utils';
import { EmbarkHello, EmbarkHelloLegacy } from '../embark-hello';
import Introduction from '../introduction';
import PetInfo from '../pet-info';
import PetQuestions from '../pet-questions';
import Register from '../register';
import { QuoteFlowTransition } from '../shared';

import styles from './quote-flow.css';

export function QuoteFlow() {
  const { embarkStrategicDiscount, outageFlag, wp86ABTestingForWordPress } =
    useFlags();

  const location = useLocation();
  const { pathname } = location;

  const quoteId = getCookie(QUOTE_ID_COOKIE_NAME);

  const activePet = useSelector(getQuotesActivePet);
  const quotePartner = useSelector(getQuotesPartner);

  const { data: discountData } = useDiscount();
  const discountId = getDiscountId(discountData);

  const { data: quoteData, isFetched } = useQuote();
  const petsData = getQuotePets(quoteData);
  const canRouteToPlan = canGoToPlanPage(quoteData);
  const canRouteToRegister = petsData.some(pet => pet.petGender);
  const canRouteToPetInfo = petsData.some(pet => pet.petName);
  const quoteParams = Store.get(QUOTE_URL_PARAMS_KEY) || {};
  const canRouteToHeyFlow =
    !getIsLoggedIn() &&
    !quoteId &&
    !discountId &&
    wp86ABTestingForWordPress === 'https://get-quote.pumpkin.care/' &&
    !('utm_source' in quoteParams);
  const alreadyInHeyflowTest = quotePartner === 'Heyflow Widget Test';

  const isWideComponent =
    embarkStrategicDiscount && [Paths.EmbarkHello].includes(pathname);

  useEffect(() => {
    if (wp86ABTestingForWordPress && !getIsLoggedIn()) {
      analyticsTrack({
        event: INITIATE_EXPERIMENT,
        category: 'Heyflow Widget Test',
        label: canRouteToHeyFlow || alreadyInHeyflowTest ? 'b_test' : 'a_control',
      });
      analyticsTrack({
        event: INITIATE_EXPERIMENT,
        category: 'heyflow_LD_original_tag',
        label: wp86ABTestingForWordPress,
      });
    }
  }, [wp86ABTestingForWordPress, canRouteToHeyFlow, alreadyInHeyflowTest]);

  return outageFlag && outageFlag?.type === 'scheduled' ? (
    <Redirect
      to={{
        pathname: Paths.MaintenanceFrame,
        state: {
          timeStart: outageFlag.start,
          timeEnd: outageFlag.end,
        },
      }}
    />
  ) : (quoteId && isFetched === false) || !wp86ABTestingForWordPress ? null : (
    <div>
      <ProgressBar
        value={
          quotePartner === EMBARK
            ? calculateEmbarkQuoteFlowProgress(pathname)
            : calculateQuoteFlowProgress(pathname)
        }
      />

      <div
        className={classNames(styles.quoteFlow, {
          [styles.wide]: isWideComponent,
        })}
      >
        <QuoteFlowTransition location={location}>
          <Switch location={location}>
            <Route exact path={Paths.QuoteFlow}>
              {canRouteToHeyFlow ? (
                <HeyFlowRedirect />
              ) : canRouteToPlan ? (
                <Redirect to={Paths.PlanSelection} />
              ) : canRouteToRegister ? (
                <Redirect to={Paths.Register} />
              ) : canRouteToPetInfo ? (
                <Redirect to={Paths.PetInfo} />
              ) : (
                <Redirect to={Paths.Intro} />
              )}
            </Route>

            <Route path={Paths.EmbarkHello}>
              {embarkStrategicDiscount ? <EmbarkHello /> : <EmbarkHelloLegacy />}
            </Route>

            <Route path={Paths.Intro}>
              {canRouteToHeyFlow ? <HeyFlowRedirect /> : <Introduction />}
            </Route>

            <Route path={Paths.PetInfo}>
              <PetInfo />
            </Route>

            <Route path={Paths.PetQuestions}>
              <PetQuestions />
            </Route>

            <Route path={Paths.Register}>
              <Register activePet={activePet} />
            </Route>

            <Redirect to={Paths.Intro} />
          </Switch>
        </QuoteFlowTransition>
      </div>
    </div>
  );
}

function HeyFlowRedirect() {
  //Getting the params from QUOTE_URL_PARAMS_KEY because at this point we don't have them in the URL
  const quoteParams = Store.get(QUOTE_URL_PARAMS_KEY) || {};

  //Generating the URL params for the HeyFlow from marketing attribution params
  const urlSearchParams = new URLSearchParams(quoteParams).toString();
  const params = urlSearchParams ? `?${urlSearchParams}` : '';

  window.location.replace(`https://get-quote.pumpkin.care/${params}#start`);
}

export default QuoteFlow;
