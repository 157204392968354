import { patchIdentity } from '@pumpkincare/identity';
import { storeObserver, SUCCESS } from '@pumpkincare/shared';

import {
  getQuotesEmail,
  getQuotesFirstName,
  getQuotesLastName,
  getQuotesRegisterQuoteStatus,
} from '../../quotes/selectors';
import { getIdentityId } from '../selectors';
import getShouldCreateIdentity from '../selectors/get-should-create-identity';
import createIdentity from '../thunks/create-identity';

// once this is off feature flag, remove on ldClient on ready in appController and add to other listeners in that file
export default function identityStoreObserver(store, quoteId) {
  const { dispatch, getState } = store;

  // once segmentTrackingId and FF loaded, create identity and store in local storage if it doesn't exist already
  storeObserver(store, getShouldCreateIdentity, (config, unsub) => {
    if (config.shouldCreateIdentity && !quoteId) {
      dispatch(createIdentity());
    }

    if (config.shouldUnsubObserver) {
      unsub();
    }
  });

  // on registerQuote success, update identity
  storeObserver(store, getQuotesRegisterQuoteStatus, status => {
    if (status === SUCCESS) {
      const state = getState();

      patchIdentity({
        id: getIdentityId(state),
        email: getQuotesEmail(state),
        first_name: getQuotesFirstName(state),
        last_name: getQuotesLastName(state),
      });
    }
  });
}
