import classNames from 'classnames';

import { isUnlimitedLimit } from '@pumpkincare/plans';
import { Body2, LegalBody, Superscript1 } from '@pumpkincare/shared/ui';

import InsuranceModal from '../insurance-modal';

import styles from './levers.module.css';

import petsCheck from './pets-check-list.png';

const LEVERS_TO_TITLES_MAP = {
  limits: 'Annual Limit',
  deductibles: 'Annual Deductible',
};

const leversObj = {
  limits: {
    title: 'Annual Coverage Limit',
    sectionItems: [
      {
        title: 'What is it?',
        copy: (
          <>
            The max amount of eligible vet bills your pet insurance plan can
            reimburse you for each policy year.
          </>
        ),
      },
      {
        title: 'Which do I choose?',
        copy: (
          <>
            While a $10k annual limit is high enough for 98% of recent pupstomers and
            a $7k limit is high enough for 97% of recent catstomers, we offer higher
            options if you're worried about expensive vet bills or want extra peace
            of mind.{' '}
            <Superscript1
              role='button'
              className={styles.supScript}
              onClick={() =>
                window.scrollTo({
                  top: document.body.scrollHeight,
                  behavior: 'smooth',
                })
              }
            >
              1
            </Superscript1>
          </>
        ),
      },
    ],
    footer: {
      items: [
        {
          title: 'Tip!',
          copy: 'You can’t increase your annual limit after you set it, so choose carefully!',
        },
      ],
      img: '/assets/images/iconImages/pets-saving.png',
    },
  },

  deductibles: {
    title: 'Annual Deductible',
    sectionItems: [
      {
        title: 'What is it?',
        copy: (
          <>
            The amount you pay upfront towards eligible vet bills each policy year
            before your 90% cash back kicks in.
          </>
        ),
      },
      {
        title: 'Which do I choose?',
        copy: (
          <>
            Choose a $500 or $1k annual deductible if you’re worried about high vet
            bills or multiple accidents & illnesses. If neither are a concern, $100
            or $250 may be right for you.
          </>
        ),
      },
    ],
    footer: {
      img: petsCheck,
      items: [
        {
          title: 'Tip!',
          copy: 'The higher the annual deductible, the lower your monthly cost!',
        },
      ],
    },
  },
};
function Levers({ activeValues = {}, levers = {}, onChange, isActive = false }) {
  function handleOnChangeValue(values) {
    const { key, value } = values;

    const currentValues = {
      annual_limit: activeValues.annual_limit,
      deductible: activeValues.deductible,
    };

    const changedValues = { ...currentValues, [key]: value };

    onChange(changedValues);
  }

  const leverClassName = classNames(styles.lever, {
    [styles.leverInactive]: !isActive,
  });

  return (
    <>
      {Object.keys(levers).map(leverKey => {
        return (
          <div key={leverKey} className={styles.container}>
            <div
              className={styles.leverRecommendation}
              data-testid='lever-recommendation'
            >
              <div className={styles.titleWrapper}>
                <Body2 className={styles.leverTitle}>
                  {LEVERS_TO_TITLES_MAP[leverKey]}
                </Body2>

                <InsuranceModal
                  modalData={leversObj[leverKey] ?? {}}
                  classes={{
                    modalTitle: styles.modalTitle,
                    modalIcon: styles.modalIcon,
                  }}
                />
              </div>
            </div>

            <div className={styles.levers}>
              {levers[leverKey].map(item => {
                const isSelected =
                  activeValues[item.option.key] === item.option.value;

                return (
                  <button
                    key={item.title}
                    className={leverClassName}
                    aria-pressed={isSelected}
                    onClick={() => handleOnChangeValue(item.option)}
                  >
                    <LegalBody isBold>
                      {isUnlimitedLimit(item.option.value)
                        ? item.option.value
                        : `$${Number(item.option.value).toLocaleString('en')}`}
                    </LegalBody>
                  </button>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
}

export default Levers;
