import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { useQuote } from '@pumpkincare/quotes';
import { Body1, LegalBody } from '@pumpkincare/shared/ui';

import { getQuoteFlowNextStep, getQuotesActiveId } from '../../selectors';
import QuoteFlowHeader from '../quote-flow-header';
import { NextButton, QuoteFlowComponentContent } from '../shared';

import styles from './embark-hello.css';

export default function EmbarkHello() {
  const dispatch = useDispatch();
  const activeId = useSelector(getQuotesActiveId);
  const { data: quoteData, isLoading } = useQuote();
  const { firstName, pets } = quoteData;
  const activePetName = pets.find(pet => pet.id === activeId)?.petName;
  const nextRoute = useSelector(getQuoteFlowNextStep);

  function handleNext() {
    dispatch(push(nextRoute));
  }

  return (
    <>
      <QuoteFlowComponentContent>
        <div className={styles.contentWrapper}>
          <div className={styles.item}>
            <img
              src={'/assets/images/cartoons/EmbarkDogDiscount.png'}
              role={'presentation'}
              alt='Embark customers can save up to 10% on a Pumpkin insurance plan. Restrictions apply'
              className={styles.discountImage}
            />
            <LegalBody className={styles.finePrint}>
              <p>* Discount not available in FL, HI, MN, &#38; TN.</p>
              <p>Discount amount may vary in eligible states.</p>
            </LegalBody>
          </div>

          <div className={styles.item}>
            <QuoteFlowHeader className={styles.greeting}>
              {firstName && activePetName
                ? `Hi, ${firstName} & ${activePetName}!`
                : `Hi!`}
            </QuoteFlowHeader>

            <Body1 style={{ marginTop: '16px' }}>
              With Embark, you get priceless information about{' '}
              {`${activePetName || 'your pet'}’s`} genetic &#38; health. With a
              Pumpkin insurance plan, it can be easier to afford best care if illness
              or accidents do pop up in the future!
            </Body1>

            <Body1 style={{ marginTop: '12px' }}>
              Just FYI, your Embark health results will...
            </Body1>

            <div className={styles.listWrapper}>
              <div>
                <img
                  src={'/assets/images/cartoons/01_Illustrations_Misc_Star.png'}
                  className={styles.listMarker}
                />
              </div>
              <LegalBody className={styles.list}>
                <span className={styles.bold}>NEVER</span> increase the price of your
                Pumpkin insurance plan
              </LegalBody>

              <div>
                <img
                  src={'/assets/images/cartoons/01_Illustrations_Misc_Star.png'}
                  className={styles.listMarker}
                />
              </div>
              <LegalBody className={styles.list}>
                <span className={styles.bold}>HELP</span> detect risks for breed or
                hereditary conditions that could be covered by insurance if they are
                eligible for coverage.
              </LegalBody>

              <div>
                <img
                  src={'/assets/images/cartoons/01_Illustrations_Misc_Star.png'}
                  className={styles.listMarker}
                />
              </div>
              <LegalBody className={styles.list}>
                <span className={styles.bold}>ONLY</span> further our vision to
                create a happier, healthier world for pets &#38; their people 🐶
              </LegalBody>
            </div>

            <NextButton
              onClick={handleNext}
              disabled={isLoading}
              text="Let's Get Started"
            />
          </div>
        </div>
      </QuoteFlowComponentContent>
    </>
  );
}
