import { INITIAL } from '@pumpkincare/shared';

const SET_PLAN_PAGE_LOAD_STATUS = 'plans/SET_PLAN_PAGE_LOAD_STATUS';
const SET_PLAN_PRODUCTS = 'plans/SET_PLAN_PRODUCTS';

const initialState = {
  pageLoadStatus: INITIAL,
  products: {
    insurance: false,
    prevent: false,
  },
  recommendationOptions: {},
  policyOptions: {},
};

export default function plansReducer(state = initialState, { payload, type }) {
  switch (type) {
    case SET_PLAN_PAGE_LOAD_STATUS: {
      return {
        ...state,
        pageLoadStatus: payload,
      };
    }

    case SET_PLAN_PRODUCTS: {
      return {
        ...state,
        products: payload,
      };
    }

    default: {
      return state;
    }
  }
}

export function setPlanPageLoadStatus(status) {
  return {
    type: SET_PLAN_PAGE_LOAD_STATUS,
    payload: status,
  };
}

export function setPlanProducts(payload) {
  return { type: SET_PLAN_PRODUCTS, payload };
}
