/* eslint-disable react/jsx-no-target-blank */
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import classNames from 'classnames';

import { WWW_URL } from '@pumpkincare/config';
import {
  getPlanWebDescription,
  useBreedDefinition,
  usePlan,
} from '@pumpkincare/plans';
import {
  getQuoteActivePet,
  getQuoteCompletePets,
  getQuotePets,
  getQuotePolicyState,
  getQuoteVetId,
  sortLegalData,
  useQuote,
} from '@pumpkincare/quotes';
import {
  CONTACT_EMAIL,
  CONTACT_EMAIL_LINK,
  getIsLoggedIn,
} from '@pumpkincare/shared';
import { getUserId, useUssr } from '@pumpkincare/user';

import { getQuotesActiveId } from '../../../../quotes/selectors';
import Paths from '../../../paths';
import { compileLegalData } from '../utils/footer-utils';

import styles from './legal.module.css';

function Legal() {
  const { pathname } = useLocation();
  const isPlanPage = !!matchPath(pathname, [Paths.PlanSelection]);
  const isCheckoutPage = !!matchPath(pathname, [Paths.Checkout]);

  const { data: quoteData } = useQuote();
  const activeId = useSelector(getQuotesActiveId);
  const activePet = getQuoteActivePet(quoteData)(activeId);
  const vetId = getQuoteVetId(quoteData);
  const policyState = getQuotePolicyState(quoteData);
  const { data: userData } = useUssr();
  const userId = getUserId(userData);
  const { data: planData } = usePlan(
    activePet?.petAge,
    activePet?.petBreedSpecies,
    userId,
    vetId
  );

  const { data: planPageData = {} } = useBreedDefinition({
    petId: activeId,
  });

  const webDescription = planData ? getPlanWebDescription(planData) : '';
  const { legal } = webDescription;

  const pets = isCheckoutPage
    ? getIsLoggedIn()
      ? getQuotePets(quoteData)
      : getQuoteCompletePets(quoteData)
    : null;

  const sortedLegal =
    isPlanPage && !!legal
      ? sortLegalData(legal)
      : isCheckoutPage && !!pets.length
      ? compileLegalData(pets)
      : null;

  const heartIconClassName = classNames(styles.heartIcon, {
    [styles.right]: !isPlanPage || !isCheckoutPage,
    [styles.left]: !!isPlanPage || !!isCheckoutPage,
  });

  return (
    <div className={styles.root}>
      <div className={styles.darkBlueWave} />

      <img
        className={heartIconClassName}
        src='/assets/images/heart_scout_navy.svg'
        alt=''
      />

      <div className={styles.container}>
        {isPlanPage ? (
          <>
            <span className={classNames(styles.headerText)}>REFERENCES:</span>

            <p className={classNames(styles.legalText, styles.footnotesSection)}>
              {(planPageData?.footnote || []).map(item => (
                <span className={styles.footnotesItem} key={item.copy[0]}>
                  {item?.copyLocation?.[policyState] ?? item.copy}
                </span>
              ))}
            </p>
          </>
        ) : null}

        {isPlanPage ? (
          <div className={styles.terms}>
            {sortedLegal
              ? sortedLegal.map((item, index) => {
                  return (
                    <div key={index} className={styles.legalText}>
                      <b dangerouslySetInnerHTML={{ __html: item.title }} />

                      <br />

                      <span dangerouslySetInnerHTML={{ __html: item.content }} />
                    </div>
                  );
                })
              : null}

            <div className={styles.legalText}>
              <b>COMPARISON INFORMATION:</b>

              <br />

              <span>
                Comparison information is provided using publicly available
                information as of 9/12/2023 and is only meant to summarize program
                features, not a specific plan. Review the provider’s plan terms for
                more details. The description of the providers’ plans are not
                provided by that company. If you have questions about other plans,
                please contact an agent of that company. It is our intention to
                provide fair and accurate comparison information. We attempt to keep
                information up to date but it may change from time to time. If you
                are aware of any inaccuracies or changes in the information provided,
                let us know by emailing{' '}
                <a href={CONTACT_EMAIL_LINK} className={styles.legalLink}>
                  {CONTACT_EMAIL}
                </a>
              </span>
            </div>

            {isCheckoutPage || isPlanPage ? (
              <div className={styles.legalText}>
                <b>TRUPANION INFORMATION:</b>

                <br />

                <span>
                  As of 5/18/2023, Trupanion has an updated policy available in TX,
                  FL, ME, AZ and may be available in other states soon. Please refer
                  to trupanion.com for the latest details on which states offer this
                  updated policy. Trupanion’s updated policy now includes the
                  following: 1. Prescription food: covered for 90 days for the
                  lifetime of the pet, subject to the reimbursement rate chosen by
                  the member. 2. Dental illnesses: an annual dental exam is not
                  required. 3. Behavioral issues & alternative therapies: Rx
                  medication for behavioral issues is covered, but not behavioral
                  training, modification, or assessment; alternative therapies are
                  covered. 4: Reimbursement rates & deductibles: There are no
                  deductibles and customizable reimbursement rate options ranging
                  from 70%-100%. 5. Waiting periods: In FL, there is a 12 day waiting
                  period. In TX and AZ, there are no waiting periods, but a 12 day
                  delayed effective date. In ME, there is no waiting period or
                  delayed effective date. In states where the updated policy is not
                  available, there is a 30 day illness waiting period and 5 day
                  accident waiting period.
                </span>
              </div>
            ) : null}

            <div className={styles.legalText}>
              <span className={styles.headerText}>
                30-DAY MONEY BACK GUARANTEE TERMS:
              </span>
              <p className={styles.legalText}>
                Full refund of insurance monthly premium, only if no covered claims
                have been filed. Not available in all states. New York residents will
                receive a prorated refund for the remaining days in the month.
                Louisiana, Maine and Washington residents receive a 15 day money back
                guarantee.
              </p>
            </div>

            {!isPlanPage ? (
              <>
                <span className={styles.headerText}>
                  30-DAY MONEY BACK GUARANTEE TERMS:
                </span>
                <p className={styles.legalText}>
                  Full refund of insurance monthly premium, only if no covered claims
                  have been filed. Not available in all states. New York residents
                  will receive a prorated refund for the remaining days in the month.
                  Maine residents receive a 15-day money back guarantee.
                </p>
              </>
            ) : null}
          </div>
        ) : null}

        <div>
          <div className={styles.link}>
            <a
              href={`${WWW_URL}/references`}
              rel='noopener'
              target='_blank'
              className={styles.linkText}
            >
              SEE ALL REFERENCES
              <img src='/assets/images/iconImages/chevrons-right-white.svg' alt='' />
            </a>
          </div>

          <div className={styles.link}>
            <a
              href={`${WWW_URL}/insurer-disclosure`}
              rel='noopener'
              target='_blank'
              className={styles.linkText}
            >
              INSURER DISCLOSURE OF IMPORTANT POLICY PROVISIONS
              <img src='/assets/images/iconImages/chevrons-right-white.svg' alt='' />
            </a>
          </div>

          <div>
            <p className={styles.legalText}>
              Pumpkin Pet Insurance policies do not cover pre-existing conditions.
              Waiting periods, annual deductible, co-insurance, benefit limits and
              exclusions may apply. For full terms, visit{' '}
              <a
                href={`${WWW_URL}/sample-plan/`}
                target='_blank'
                rel='noopener'
                className={styles.legalLink}
              >
                pumpkin.care/insurancepolicy
              </a>
              . Products and rates may vary and are subject to change. Discounts may
              vary and are subject to change. Insurance products are underwritten by
              either Independence American Insurance Company (NAIC #26581. A Delaware
              insurance company headquarters located at 11333 N. Scottsdale Rd, Ste.
              160, Scottsdale, AZ 85254), or United States Fire Insurance Company
              (NAIC #21113. Morristown, NJ). Please refer to your policy forms to
              determine the underwriter for your policy. Insurance is administered
              and produced by Pumpkin Insurance Services Inc. (“Pumpkin”) (NPN
              #19084749; Domiciled in New York with offices at 432 Park Avenue South,
              Floor 12, New York, NY 10016; CA License #6001617). Pumpkin is a
              licensed insurance agency, not an insurer, and receives compensation
              based on the premiums for the insurance policies it sells. For more
              details, visit{' '}
              <a
                href={`${WWW_URL}/underwriting-information`}
                target='_blank'
                rel='noopener'
                className={styles.legalLink}
              >
                pumpkin.care/underwriting-information
              </a>
              . Pumpkin Preventive Essentials is not an insurance policy. It is
              offered as an optional add-on non-insurance benefit. Pumpkin is
              responsible for the product and administration. For full terms, visit{' '}
              <a
                href={`${WWW_URL}/sample-plan`}
                target='_blank'
                rel='noopener'
                className={styles.legalLink}
              >
                pumpkin.care/customeragreement
              </a>
              . Pumpkin Preventive Essentials is not available in all states.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Legal;
