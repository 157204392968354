import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { matchPath } from 'react-router';
import { Elements } from '@stripe/react-stripe-js';
import { Auth } from 'aws-amplify';
import { ConnectedRouter } from 'connected-react-router';
import _ from 'lodash';
import queryString from 'query-string';
import Store from 'store';

import {
  configureAmplifyUserSrpAuth,
  LAUNCH_DARKLEY_KEY,
} from '@pumpkincare/config';
import {
  determineMarketingChannelPayload,
  setMarketingAttribution,
} from '@pumpkincare/marketing';
import {
  getQuoteById,
  QUOTE_ID_COOKIE_NAME,
  QUOTE_QUERY,
  transformQuoteRedux,
} from '@pumpkincare/quotes';
import {
  BannersProvider,
  captureException,
  getCookie,
  IDENTITY_LOCAL_STORAGE_KEY,
  matchScreenSizes,
  QUOTE_URL_PARAMS_KEY,
  setCookie,
  setIsLoggedIn,
} from '@pumpkincare/shared';
import { GlobalStylesInject } from '@pumpkincare/shared/ui';
import { getUserQueryFn, USER_SELF_QUERY } from '@pumpkincare/user';

import { identityStoreObservers, setUpdatedIdentity } from '../identity';
import { setQuoteResponseV2 } from '../quotes';
import { getQuotes } from '../quotes/selectors';
import {
  setAppAgent,
  setFeatureFlags,
  setMediaMatches,
  setNoopPayload,
} from './state/app-ducks';
import appStoreObservers from './state/app-store-observers';
import { createQuoteFlowStore, history } from './state/redux-store';
import getLaunchDarklyUser from './state/selectors/get-launch-darkly-user';
import App from './view/app';
import { configureApp, initLaunchDarkly, initStripe } from './config.js';
import Paths from './paths';

export default function AppController() {
  const store = createQuoteFlowStore();
  const dispatch = store.dispatch;
  const getState = store.getState;

  const { quoteId: searchQuoteId, identityId: searchIdentityId } = queryString.parse(
    window.location.search
  );
  const { params } =
    matchPath(window.location.pathname, {
      path: [Paths.PlanSelectionDeepLink, Paths.CheckoutDeepLink],
      exact: true,
    }) || {};

  const quoteIdFromURL = searchQuoteId || params?.quoteId;

  if (searchIdentityId) store.dispatch(setUpdatedIdentity({ id: searchIdentityId }));

  if (quoteIdFromURL) setCookie(QUOTE_ID_COOKIE_NAME, quoteIdFromURL);

  const quoteId = quoteIdFromURL || getCookie(QUOTE_ID_COOKIE_NAME);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        onError: captureException,
        refetchOnWindowFocus: false,
        retry: 0,
      },
    },
  });

  // bootstrap needs to happen before initLaunchDarkly for parseQueryString
  bootstrap();
  authenticateUser();

  const { withLaunchDarkly, ldClient } = initLaunchDarkly(
    LAUNCH_DARKLEY_KEY,
    getLaunchDarklyUser(getState())
  );

  const EnhancedApp = withLaunchDarkly(App);

  addListeners();
  render();

  function addListeners() {
    appStoreObservers(store, ldClient);
    identityStoreObservers(store, params?.quoteId);

    // todo dont dispatch on every resize, only if breakpoints change
    window.addEventListener(
      'resize',
      _.debounce(() => {
        dispatch(setMediaMatches(matchScreenSizes()));
      }, 100)
    );

    ldClient.on('ready', () => {
      const featureFlags = ldClient.allFlags();

      dispatch(setFeatureFlags(featureFlags));
    });
  }

  function setQuoteOnRedux(quote) {
    const { identity_id: identityId } = quote;

    // Transform the quote using the current state
    const transformedQuote = transformQuoteRedux(quote, getQuotes(getState()));

    // Set the transformed quote response
    dispatch(setQuoteResponseV2(transformedQuote));

    dispatch(setUpdatedIdentity({ id: identityId }));

    // Store identityId in local storage
    Store.set(IDENTITY_LOCAL_STORAGE_KEY, identityId);
    return quote;
  }

  function bootstrap() {
    if (quoteId) {
      queryClient.prefetchQuery([QUOTE_QUERY, quoteId], () =>
        getQuoteById({ quoteId, onSuccess: setQuoteOnRedux })
      );
    }

    parseQueryString();

    dispatch(setMediaMatches(matchScreenSizes()));

    configureApp(store);
  }

  function parseQueryString() {
    const values = queryString.parse(window.location.search);
    const { agentId } = values;

    if (agentId) {
      dispatch(setAppAgent(agentId));
    }

    if ('no_op' in values) {
      dispatch(setNoopPayload({ no_op: true }));
    }

    if (Object.keys(values).length) {
      setMarketingAttribution(determineMarketingChannelPayload(values));

      Store.set(QUOTE_URL_PARAMS_KEY, values);
    }
  }

  function authenticateUser() {
    configureAmplifyUserSrpAuth();

    Auth.currentAuthenticatedUser()
      .then(() => {
        setIsLoggedIn(true);

        queryClient.prefetchQuery([USER_SELF_QUERY], () => getUserQueryFn());
      })
      .catch(() => {
        setIsLoggedIn(false);
      });
  }

  function render() {
    const container = document.getElementById('root');
    const root = createRoot(container);

    root.render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Elements
            stripe={initStripe(dispatch, getState())}
            options={{
              fonts: [
                {
                  // load custom font to Stripe iFrame
                  cssSrc: 'https://fonts.googleapis.com/css?family=Nunito+Sans',
                },
              ],
            }}
          >
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />

              <BannersProvider>
                <GlobalStylesInject />
                <EnhancedApp />
              </BannersProvider>
            </QueryClientProvider>
          </Elements>
        </ConnectedRouter>
      </Provider>
    );
  }
}
