import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'connected-react-router';

import { useMarketingAttribution } from '@pumpkincare/marketing';
import {
  canGoToPlanPage,
  useBreedDefinition,
  usePetPlanData,
} from '@pumpkincare/plans';
import {
  getQuoteActivePet,
  getQuoteId,
  getQuoteIsRegistered,
  getQuoteVetId,
  useQuote,
} from '@pumpkincare/quotes';
import { getIsLoggedIn } from '@pumpkincare/shared';
import { LoadingScreen } from '@pumpkincare/shared/ui';

import { Paths } from '../../../app-shell';
import { getIdentityId } from '../../../identity/selectors';
import { setActiveId } from '../../../quotes';
import { getQuotesActiveId } from '../../../quotes/selectors';
import EcommViewV2 from '../ecomm-view-v2';
import PlanSelectionPixels from './plan-selection-pixels';

function PlanSelection() {
  const isLoggedIn = getIsLoggedIn();

  const dispatch = useDispatch();
  const identityId = useSelector(getIdentityId);
  const activeId = useSelector(getQuotesActiveId);

  const { data: planPageData } = useBreedDefinition({
    petId: activeId,
  });

  const {
    data: quoteData,
    isPlaceholderData: isQuotePlaceholderData,
    isFetching: isQuoteFetching,
  } = useQuote();
  const quoteId = getQuoteId(quoteData);
  const vetId = getQuoteVetId(quoteData);
  const isQuoteRegistered = getQuoteIsRegistered(quoteData);
  const activePet = getQuoteActivePet(quoteData)(activeId);

  // forcing to fetch all necessary data here so it's available in cache when we render child components
  const { isReady } = usePetPlanData({ petId: activePet?.id, identityId, vetId });

  // this will account for leaving the page and returning and for refreshing the page
  const isFirstVisitWithAttribution = useMarketingAttribution(
    quoteId,
    'Land on Plan Selection',
    'isGoingToPlanSelection'
  );

  const { petName = '' } = activePet || {};

  useEffect(() => {
    // handling incomplete quote
    if (!quoteId) {
      dispatch(replace('/'));
    } else if (
      isQuotePlaceholderData === false &&
      isQuoteFetching === false &&
      canGoToPlanPage(quoteData) === false
    ) {
      dispatch(replace(Paths.QuoteFlow));
    }
  }, [
    dispatch,
    isQuoteFetching,
    isQuotePlaceholderData,
    isQuoteRegistered,
    quoteData,
    quoteId,
  ]);

  useEffect(() => {
    // setting active pet for multi pet flow
    if (isQuotePlaceholderData === false && !activeId) {
      dispatch(setActiveId(activePet?.id));
    }
  }, [activeId, activePet?.id, dispatch, isQuotePlaceholderData]);

  return !isReady ? (
    <LoadingScreen
      petBreedSpecies={activePet?.petBreedSpecies}
      title={`Fetching ${petName ? `${petName}'s` : ''} Plan!`}
    />
  ) : (
    <>
      <EcommViewV2 planPageContent={planPageData} />

      {!isLoggedIn && isFirstVisitWithAttribution ? <PlanSelectionPixels /> : null}
    </>
  );
}

export default PlanSelection;
