import _ from 'lodash';

import { ADD, ADD_PET_LATER_DAY } from '@pumpkincare/shared';

import quotesInitialState from './quotes-initial-state';

const SET_VET_ATTRIBUTION = 'quotes/SET_VET_ATTRIBUTION';
const SET_TRACKING_ID = 'quotes/SET_TRACKING_ID';
const SET_ZIP_CODE = 'quotes/SET_ZIP_CODE';
const SET_POLICY_STATE = 'quotes/SET_POLICY_STATE';
const SET_EMAIL_ADDRESS = 'quotes/SET_EMAIL_ADDRESS';
const SET_FIRST_NAME = 'quotes/SET_FIRST_NAME';
const SET_LAST_NAME = 'quotes/SET_LAST_NAME';
const ANALYTICS = 'quotes/ANALYTICS';
const SET_ACTIVE_ID = 'quotes/SET_ACTIVE_ID';
const SET_UPDATED_BILLING_FIELD = 'quotes/SET_UPDATED_BILLING_FIELD';
const SET_UPDATED_SHIPPING_FIELD = 'quotes/SET_UPDATED_SHIPPING_FIELD';
const SET_UPDATED_PAYMENT_FIELD = 'quotes/SET_UPDATED_PAYMENT_FIELD';
const SET_UPDATED_FIELD = 'quotes/SET_UPDATED_FIELD';
const SET_ADD_A_NEW_PET = 'quotes/SET_ADD_A_NEW_PET';
const SET_ADD_A_NEW_PET_LATER_DAY = 'quotes/SET_ADD_A_NEW_PET_LATER_DAY';
const SET_DELETE_A_NEW_PET = 'quotes/SET_DELETE_A_NEW_PET';
const CLEAR_MODE = 'quotes/CLEAR_MODE';
const SET_SUBMITTING_QUOTE_FETCHING = 'quote/SET_SUBMITTING_QUOTE_FETCHING';
const DELETE_PET = 'quotes/DELETE_PET';
const SET_QUOTE_RESPONSE_V2 = 'quotes/SET_QUOTE_RESPONSE_V2';
const SET_UPDATED_PET = 'quotes/SET_UPDATED_PET';
const SET_REGISTER_QUOTE_STATUS = 'quotes/SET_REGISTER_QUOTE_STATUS';
const SET_SAVE_ADD_PET_LATER_DAY = 'quotes/SET_SAVE_ADD_PET_LATER_DAY';
const SET_PREVENTATIVE_SELECTION = 'quotes/SET_PREVENTATIVE_SELECTION';
const RESET_QUOTES = 'quotes/RESET_QUOTES';
const SET_QUOTE_ON_INITIAL_FETCH = 'quotes/SET_QUOTE_ON_INITIAL_FETCH';
const SET_PETS = 'quotes/SET_PETS';
const SET_PET_PLAN = 'quotes/SET_PET_PLAN';

export default function quotesReducer(
  state = quotesInitialState,
  { payload, type }
) {
  switch (type) {
    case SET_TRACKING_ID: {
      return { ...state, trackingId: payload };
    }

    case SET_PETS: {
      const { pets, preEnrolledPets = [] } = payload;
      // if it's add pet at a later day
      // we combine pre enrolled pets and the newly added pets together
      // when creating the petsOrder key value store
      const petsOrder = preEnrolledPets
        .concat(payload.pets)
        .reduce((prev, curr, index) => ({ ...prev, [curr.id]: index + 1 }), {});

      return { ...state, pets, petsOrder };
    }

    case SET_UPDATED_PET: {
      return {
        ...state,
        pets: state.pets.map(pet => (pet.id === payload.id ? payload : pet)),
      };
    }

    case SET_ZIP_CODE: {
      let shippingAddress = state.shippingAddress;
      let shippingZipcode = shippingAddress.zipcode;

      return {
        ...state,
        policy_zipcode: payload,

        shippingAddress: {
          ...shippingAddress,
          zipcode: {
            ...shippingZipcode,
            value: payload,
            error: !payload,
            valid: !!payload,
          },
        },
      };
    }

    case SET_POLICY_STATE: {
      let shippingAddress = state.shippingAddress;
      let shippingState = shippingAddress.state;

      return {
        ...state,
        policy_state: payload,

        shippingAddress: {
          ...shippingAddress,

          state: {
            ...shippingState,
            value: payload,
            error: !payload,
            valid: !!payload,
          },
        },
      };
    }

    case SET_EMAIL_ADDRESS: {
      return { ...state, email: payload };
    }

    case SET_FIRST_NAME: {
      let shippingAddress = state.shippingAddress;
      let shippingFirstName = shippingAddress.firstName;

      return {
        ...state,
        firstName: payload,

        shippingAddress: {
          ...shippingAddress,

          firstName: {
            ...shippingFirstName,
            value: payload,
            error: !payload,
            valid: !!payload,
          },
        },
      };
    }

    case SET_LAST_NAME: {
      let shippingAddress = state.shippingAddress;
      let shippingLastName = shippingAddress.lastName;

      return {
        ...state,
        lastName: payload,

        shippingAddress: {
          ...shippingAddress,

          lastName: {
            ...shippingLastName,
            value: payload,
            error: !payload,
            valid: !!payload,
          },
        },
      };
    }

    case SET_UPDATED_BILLING_FIELD: {
      let billingAddress = state.billingAddress;
      let fieldObj = billingAddress[payload.field];

      return {
        ...state,

        billingAddress: {
          ...billingAddress,

          [payload.field]: {
            ...fieldObj,
            value: payload.value,
            error: payload.error,
            valid: payload.valid,
          },
        },
      };
    }

    case SET_UPDATED_SHIPPING_FIELD: {
      let shippingAddress = state.shippingAddress;
      let fieldObj = shippingAddress[payload.field];

      return {
        ...state,

        shippingAddress: {
          ...shippingAddress,

          [payload.field]: {
            ...fieldObj,
            value: payload.value,
            error: payload.error,
            valid: payload.valid,
          },
        },
      };
    }

    case SET_UPDATED_PAYMENT_FIELD: {
      const paymentInfo = state.paymentInfo;
      const { field, ...restPayload } = payload;

      return {
        ...state,

        paymentInfo: {
          ...paymentInfo,

          [field]: {
            ...paymentInfo[field],
            ...restPayload,
          },
        },
      };
    }

    case SET_UPDATED_FIELD: {
      let { field, value } = payload;

      return {
        ...state,
        [field]: value,
      };
    }

    case SET_ACTIVE_ID: {
      return { ...state, activeID: payload };
    }

    case SET_QUOTE_ON_INITIAL_FETCH: {
      return {
        ...state,
        ...payload,
      };
    }

    case SET_QUOTE_RESPONSE_V2: {
      // TODO do we need to account for existing pets here as in SET_PETS?
      const petsOrder = payload.pets.reduce(
        (prev, curr, index) => ({ ...prev, [curr.id]: index + 1 }),
        {}
      );

      return {
        ...state,
        ...payload,

        shippingAddress: {
          ...state.shippingAddress,

          zipcode: {
            ...state.shippingAddress.zipcode,

            value: payload.policy_zipcode,
            error: !payload.policy_zipcode,
            valid: !!payload.policy_zipcode,
          },

          state: {
            ...state.shippingAddress.state,

            value: payload.policy_state,
            error: !payload.policy_state,
            valid: !!payload.policy_state,
          },

          firstName: {
            ...state.shippingAddress.firstName,

            value: payload.firstName,
            error: !payload.firstName,
            valid: !!payload.firstName,
          },

          lastName: {
            ...state.shippingAddress.lastName,

            value: payload.lastName,
            error: !payload.lastName,
            valid: !!payload.lastName,
          },
        },

        submitQuoteRequest: {
          fetching: false,
          success: true,
        },

        petsOrder,

        vetAttribution: {
          ...state.vetAttribution,
          data: payload.vetAttribution,
        },
      };
    }

    case SET_SAVE_ADD_PET_LATER_DAY: {
      return {
        ...state,
        pets: state.pets.map(pet => {
          if (pet.id === state.activeID) {
            pet.plan = pet.plan || {};
          }

          return pet;
        }),
        transactionFee: payload.transaction_fee,
        submitQuoteRequest: {
          fetching: false,
          success: true,
        },
      };
    }

    case SET_DELETE_A_NEW_PET: {
      const petsOrder = _.clone(state.petsOrder);
      delete petsOrder[payload.id];
      const pets = state.pets.filter(pet => pet.id !== payload.id);

      return {
        ...state,
        activeID: state.prevActiveID,
        pets,
        petsOrder,
      };
    }

    case SET_ADD_A_NEW_PET: {
      const { id, petName } = payload;
      const pets = [
        ...state.pets,
        {
          id,
          petName,
          preventativeSelected: true,
        },
      ];
      const petsOrder = state.petsOrder;
      petsOrder[id] = pets.length;

      return {
        ...state,
        mode: ADD,
        activeID: id,
        prevActiveID: state.activeID,
        pets,
        petsOrder,
      };
    }

    case SET_ADD_A_NEW_PET_LATER_DAY: {
      const { id, petName } = payload;

      return {
        ...state,
        id: null,
        prevActiveID: state.activeID,
        activeID: id,
        mode: ADD,
        stage: ADD_PET_LATER_DAY,
        pets: [
          {
            id,
            petName,
            preventativeSelected: true,
          },
        ],
      };
    }

    case CLEAR_MODE: {
      return {
        ...state,
        mode: '',
      };
    }

    case SET_SUBMITTING_QUOTE_FETCHING: {
      return {
        ...state,
        submitQuoteRequest: {
          fetching: true,
          success: state.submitQuoteRequest.success,
        },
      };
    }

    case SET_PET_PLAN: {
      return {
        ...state,
        pets: state.pets.map(pet =>
          pet.id === payload.id
            ? {
                ...pet,
                plan: payload.plan,
              }
            : pet
        ),
      };
    }

    case SET_PREVENTATIVE_SELECTION: {
      const { petId, preventativeSelected } = payload;

      return {
        ...state,

        pets: state.pets.map(pet => {
          return pet.id === petId ? { ...pet, preventativeSelected } : pet;
        }),
      };
    }

    case SET_REGISTER_QUOTE_STATUS: {
      return {
        ...state,
        registerQuoteStatus: payload,
      };
    }

    case DELETE_PET: {
      const petsOrder = { ...state.petsOrder };
      const pets = state.pets.filter(pet => pet.id !== payload);

      delete petsOrder[payload];

      return {
        ...state,

        activeID: pets[0].id,
        pets,
        petsOrder,
      };
    }

    case SET_VET_ATTRIBUTION: {
      return {
        ...state,
        vetAttribution: {
          status: payload.status,
          data: payload.data,
        },
      };
    }

    case RESET_QUOTES: {
      return { ...quotesInitialState };
    }

    default: {
      return state;
    }
  }
}

export function setQuoteVetAttribution(status, data = {}, analytics = {}) {
  return {
    type: SET_VET_ATTRIBUTION,
    payload: { status, data },
    ...analytics,
  };
}

export function setTrackingId(payload) {
  return {
    type: SET_TRACKING_ID,
    payload: payload,
  };
}

export function setQuotesPolicyZipCode(zipCode, analytics = {}) {
  return {
    type: SET_ZIP_CODE,
    payload: zipCode,
    ...analytics,
  };
}

export function setQuotesPolicyState(policyState, analytics = {}) {
  return {
    type: SET_POLICY_STATE,
    payload: policyState,
    ...analytics,
  };
}

export function setQuotesEmail(email, analytics = {}) {
  return {
    type: SET_EMAIL_ADDRESS,
    payload: email,
    ...analytics,
  };
}

export function setQuotesFirstName(firstName, analytics = {}) {
  return {
    type: SET_FIRST_NAME,
    payload: firstName,
    ...analytics,
  };
}

export function setQuotesLastName(lastName, analytics = {}) {
  return {
    type: SET_LAST_NAME,
    payload: lastName,
    ...analytics,
  };
}

export function setAnalytics(analytics = {}) {
  return {
    type: ANALYTICS,
    ...analytics,
  };
}

export function setActiveId(petId, analytics = {}) {
  return {
    type: SET_ACTIVE_ID,
    payload: petId,
    ...analytics,
  };
}

export function setUpdatedQuoteBillingField(payload) {
  return {
    type: SET_UPDATED_BILLING_FIELD,
    payload,
  };
}

export function setUpdatedQuoteShippingField(payload) {
  return {
    type: SET_UPDATED_SHIPPING_FIELD,
    payload,
  };
}

export function setUpdatedQuotePaymentField(payload) {
  return {
    type: SET_UPDATED_PAYMENT_FIELD,
    payload,
  };
}

export function setUpdatedQuoteField(payload) {
  return {
    type: SET_UPDATED_FIELD,
    payload,
  };
}

export function setAddANewPet(payload, analytics = {}) {
  return {
    type: SET_ADD_A_NEW_PET,
    payload,
    ...analytics,
  };
}

export function setAddANewPetLaterDay(payload, analytics = {}) {
  return {
    type: SET_ADD_A_NEW_PET_LATER_DAY,
    payload,
    ...analytics,
  };
}

export function setDeleteNewPet(id, analytics = {}) {
  return {
    type: SET_DELETE_A_NEW_PET,
    payload: { id },
    ...analytics,
  };
}

export function clearMode() {
  return { type: CLEAR_MODE };
}

export function deletePet(payload, analytics = {}) {
  return {
    type: DELETE_PET,
    payload,
    ...analytics,
  };
}

export function setQuoteResponseV2(payload, analytics = {}) {
  return {
    type: SET_QUOTE_RESPONSE_V2,
    payload,
    ...analytics,
  };
}

export function setSubmittingQuoteFetching() {
  return { type: SET_SUBMITTING_QUOTE_FETCHING };
}

export function setUpdatedPet(payload, analytics = {}) {
  return {
    type: SET_UPDATED_PET,
    payload,
    ...analytics,
  };
}

export function setRegisterQuoteStatus(payload) {
  return {
    type: SET_REGISTER_QUOTE_STATUS,
    payload,
  };
}

export function setSaveAddPetLaterDay(payload) {
  return {
    type: SET_SAVE_ADD_PET_LATER_DAY,
    payload,
  };
}

export function setPreventativeSelection(petId, preventativeSelected) {
  return {
    type: SET_PREVENTATIVE_SELECTION,
    payload: { petId, preventativeSelected },
  };
}

export function resetQuotes() {
  return { type: RESET_QUOTES };
}

export function setQuoteOnInitialFetch(payload) {
  return {
    type: SET_QUOTE_ON_INITIAL_FETCH,
    payload,
  };
}

export function setPets(payload, analytics = {}) {
  return {
    type: SET_PETS,
    payload,
    ...analytics,
  };
}

export function setPetPlan(payload) {
  return {
    type: SET_PET_PLAN,
    payload,
  };
}
