/* eslint-disable react/jsx-no-target-blank */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { push } from 'connected-react-router';

import { MC_URL, WWW_URL } from '@pumpkincare/config';
import {
  ARF_MEOW_PHONE_NUMBER,
  CONTACT_EMAIL,
  CONTACT_EMAIL_LINK,
  PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import { ButtonStyles, LegalBody, Typography } from '@pumpkincare/shared/ui';

import Paths from '../../paths';
import { leaveAgentMode } from '../../state/app-ducks';
import { getAppAgentId } from '../../state/selectors/app-selectors';
import MobileMenu from '../mobile-menu';

import styles from './nav-bar.module.css';

function NavBar() {
  const dispatch = useDispatch();
  const agentId = useSelector(getAppAgentId);

  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

  const mobileMenuButtonClassName = classNames(styles.mobileMenuButton);
  const topMenuContainerClassName = classNames(styles.topMenuContainer, {
    [styles.agentMode]: !!agentId,
  });

  function startOver() {
    dispatch({ type: 'app/RESET' });

    dispatch(push(Paths.Intro));
  }

  function handleMobileMenuItemClick() {
    setIsHamburgerOpen(!isHamburgerOpen);
  }

  return (
    <nav>
      <div className={topMenuContainerClassName}>
        <div>
          {agentId ? (
            <div className={styles.agentModeWrapper}>
              <div>
                <span>Agent ID: {agentId}</span>

                <a href={'#'} onClick={() => dispatch(leaveAgentMode())}>
                  LEAVE AGENT MODE
                </a>
              </div>
            </div>
          ) : null}

          <ul className={styles.menuWrapper}>
            <div className={classNames(Typography.body2, styles.mainMenuContainer)}>
              <div className={styles.menuIcons}>
                <div
                  className={mobileMenuButtonClassName}
                  onClick={handleMobileMenuItemClick}
                >
                  &nbsp;
                </div>

                <a
                  href={WWW_URL}
                  target='_blank'
                  rel='noopener'
                  title='Home'
                  tabIndex='-1'
                  className={styles.logoLink}
                >
                  <img
                    alt='Pumpkin Logo'
                    src={`/assets/images/Pumpkin_Logo_White.svg`}
                    className={styles.logo}
                  />
                </a>
              </div>

              {agentId ? (
                <button
                  className={classNames(styles.startOverBtn, ButtonStyles.primary)}
                  onClick={startOver}
                >
                  Start Over
                </button>
              ) : null}
            </div>
            {isHamburgerOpen ? (
              <div className={styles.mobileMenuContainer}>
                <MobileMenu onItemClick={handleMobileMenuItemClick} />
              </div>
            ) : null}
            <li className={styles.menuItem}>
              <LegalBody className={styles.legalBody}>
                <a href={PHONE_NUMBER_LINK}>{`${ARF_MEOW_PHONE_NUMBER}`}</a>
              </LegalBody>
            </li>
            <li className={styles.contactEmail}>
              <LegalBody className={styles.legalBody}>
                <a href={CONTACT_EMAIL_LINK}>{CONTACT_EMAIL}</a>
              </LegalBody>
            </li>
            <li className={styles.liDivider}>
              <div className={styles.vDivider}>&nbsp; </div>
            </li>
            <li className={styles.menuItem}>
              <LegalBody className={styles.legalBody}>
                <a
                  href={`${WWW_URL}/underwriting-information/`}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Underwriting
                </a>
              </LegalBody>
            </li>
            <li className={classNames(styles.liDivider, styles.hideLogout)}>
              <div className={styles.vDivider}>&nbsp; </div>
            </li>
            <li className={classNames(styles.menuItem, styles.hideLogout)}>
              <LegalBody className={styles.legalBody}>
                <a href={`${MC_URL}/login`} rel='noopener noreferrer'>
                  Login
                </a>
              </LegalBody>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
